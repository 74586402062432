import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment-timezone';
import './ChatbotsPageNew.css';
import { Helmet } from "react-helmet";
import Sidebar from './SidebarNew';

const ChatbotsPage = () => {
  const { t } = useTranslation();
  const [chatbots, setChatbots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInput, setPageInput] = useState('');
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [showChatbotModal, setShowChatbotModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [editForm, setEditForm] = useState({
    chatbot_alias: '',
    chatbot_name: '',
    chatbot_description: '',
    chatbot_model: '',
    chatbot_api_key: '',
    chatbot_RAG_status: false,
  });

  const chatbotsPerPage = 3;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = user ? user.user_id : null;

    if (user_id) {
      getUserChatbots(user_id);
    } else {
      setError('User not authenticated');
      setLoading(false);
    }
  }, []);

  const getUserChatbots = async (user_id) => {
    try {
      const response = await axiosInstance.post('https://dryangai.com/api/getUserChatbots/', {
        user_id,
      });
      setChatbots(response.data.chatbots);
      setImageTimestamp(Date.now()); // Update image timestamp to reflect new images
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user chatbots:', error);
      setError('Error fetching user chatbots');
      setLoading(false);
    }
  };

  const deleteUserChatbot = async (chatbot_id, chatbot_name) => {
    try {
      await axiosInstance.post('https://dryangai.com/api/delete_chatbot/', {
        chatbot_id,
        chatbot_name,
      });
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        getUserChatbots(user.user_id);
      }
    } catch (error) {
      console.error('Error deleting chatbot:', error);
      setError('Error deleting chatbot');
    }
  };

  const handleChatbotClick = (chatbot) => {
    setSelectedChatbot(chatbot);
    setEditForm({
      chatbot_alias: chatbot.chatbot_alias,
      chatbot_name: chatbot.chatbot_name,
      chatbot_description: chatbot.chatbot_description,
      chatbot_model: chatbot.chatbot_model,
      chatbot_api_key: maskApiKey(chatbot.chatbot_api_key), // Mask the API key when displaying
      chatbot_RAG_status: chatbot.chatbot_RAG_status,
    });
    setShowChatbotModal(true);
    setIsEditing(false);
  };

  const handleCloseChatbotModal = () => {
    setShowChatbotModal(false);
    setSelectedChatbot(null);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    // Unmask API key in the form field for editing
    setEditForm(prevForm => ({
      ...prevForm,
      chatbot_api_key: selectedChatbot.chatbot_api_key,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleStatusChange = () => {
    setEditForm(prevState => ({
      ...prevState,
      chatbot_RAG_status: !prevState.chatbot_RAG_status
    }));
  };

  const handleSaveClick = async () => {
    try {
      await axiosInstance.post('https://dryangai.com/api/updateChatbotInfo/', {
        chatbot_id: selectedChatbot.chatbot_id,
        ...editForm,
      });
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        getUserChatbots(user.user_id); // Refresh the chatbot list
      }
      setShowChatbotModal(false);
      setSelectedChatbot(null);
    } catch (error) {
      console.error('Error updating chatbot:', error);
      setError('Error updating chatbot');
    }
  };

  const confirmDeleteChatbot = (chatbot_id, chatbot_name) => {
    if (window.confirm(`Are you sure you want to delete the chatbot "${chatbot_name}"?`)) {
      deleteUserChatbot(chatbot_id, chatbot_name);
    }
  };

  // Pagination functions for chatbots
  const indexOfLastChatbot = currentPage * chatbotsPerPage;
  const indexOfFirstChatbot = indexOfLastChatbot - chatbotsPerPage;
  const currentChatbots = chatbots.slice(indexOfFirstChatbot, indexOfLastChatbot);

  const nextPageChatbots = () => {
    if (currentPage < Math.ceil(chatbots.length / chatbotsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPageChatbots = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const lastPageChatbots = () => {
    setCurrentPage(Math.ceil(chatbots.length / chatbotsPerPage));
  };

  const firstPageChatbots = () => {
    setCurrentPage(1);
  };

  const handlePageInputChange = (event) => {
    setPageInput(event.target.value);
  };

  const goToPageChatbots = () => {
    const page = Number(pageInput);
    if (page > 0 && page <= Math.ceil(chatbots.length / chatbotsPerPage)) {
      setCurrentPage(page);
    }
    setPageInput('');
  };

  const maskApiKey = (apiKey) => {
    const length = apiKey.length;
    if (length <= 10) {
      return apiKey;
    }
    const start = apiKey.slice(0, 5);
    const end = apiKey.slice(-5);
    return `${start}*****${end}`;
  };

  const ToggleSwitch = ({ isChecked, onChange }) => (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span className={`slider ${isChecked ? 'green' : 'red'}`}></span>
    </label>
  );

  const handleStatusToggle = async (chatbot) => {
    const newStatus = !chatbot.chatbot_status;

    try {
      await axiosInstance.post('https://dryangai.com/api/updateChatbotInfo/', {
        chatbot_id: chatbot.chatbot_id,
        chatbot_status: newStatus,
      });

      setChatbots((prevChatbots) =>
        prevChatbots.map((cb) =>
          cb.chatbot_id === chatbot.chatbot_id ? { ...cb, chatbot_status: newStatus } : cb
        )
      );
    } catch (error) {
      console.error('Error updating chatbot status:', error);
      setError('Error updating chatbot status');
    }
  };

  const handlePublicToggle = async (chatbot) => {
    const public_status = !chatbot.chatbot_public;

    try {
      await axiosInstance.post('https://dryangai.com/api/updateChatbotInfo/', {
        chatbot_id: chatbot.chatbot_id,
        chatbot_public: public_status,
      });

      setChatbots((prevChatbots) =>
        prevChatbots.map((cb) =>
          cb.chatbot_id === chatbot.chatbot_id ? { ...cb, chatbot_public: public_status } : cb
        )
      );
    } catch (error) {
      console.error('Error updating chatbot status:', error);
      setError('Error updating chatbot status');
    }
  };

  return (
    <div className="chatbot-list-content">
      <Helmet>
        <title>My chatbots list -- Dr. Yang AI</title>
        <meta name="description" content="You can customize your own chatbot which responds according to the knowledge you pre-provide to customize the chatbot." />
        <meta name="keywords" content="AI, Chatbot, Customized, Personal, Customer Service, Knowledge" />
      </Helmet>
      <h2>{t('chatbotList')}</h2>
      <Sidebar
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
      />
      <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <div className="chatbot-card-container">
          {currentChatbots.map(chatbot => (
            <div className="chatbot-card" key={chatbot.chatbot_id}>
              <a href={`https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=${chatbot.chatbot_id}`} target="_blank" rel="noopener noreferrer">
                <img
                  src={`https://dryangai.com/api/media/chatbots/${chatbot.chatbot_name}/Logo.png?t=${imageTimestamp}`}
                  alt={chatbot.chatbot_name}
                  className="chatbot-card-image"
                />
              </a>
              <div className="chatbot-card-content">
                <h3>{chatbot.chatbot_name}</h3>
                <p><strong>{t('ID')}:</strong> {chatbot.chatbot_id}</p>
                <p><strong>{t('model')}:</strong> {chatbot.chatbot_model}</p>
                <p><strong>{t('public')}:</strong>
                  <ToggleSwitch
                    isChecked={chatbot.chatbot_public}
                    onChange={() => handlePublicToggle(chatbot)}
                  />
                </p>
                <p><strong>{t('status')}:</strong>
                  <ToggleSwitch
                    isChecked={chatbot.chatbot_status}
                    onChange={() => handleStatusToggle(chatbot)}
                  />
                </p>
                <p><strong>{t('updatedAt')}:</strong> {moment(chatbot.chatbot_updated_at).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</p>
                <div className="chatbot-card-buttons">
                  <button className="btn btn-primary btn-sm" onClick={() => handleChatbotClick(chatbot)}>{t('details')}</button>
                  <button className="btn btn-danger btn-sm" onClick={() => confirmDeleteChatbot(chatbot.chatbot_id, chatbot.chatbot_name)}>{t('delete')}</button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          <button onClick={firstPageChatbots} disabled={currentPage === 1} className="first-page">
            {t('firstPage')}
          </button>
          <button onClick={prevPageChatbots} disabled={currentPage === 1} className="prev-page">
            {t('previousPage')}
          </button>
          <input
            type="text"
            value={pageInput}
            className="pageInput"
            onChange={handlePageInputChange}
            placeholder={currentPage.toString()}
          />
          <button onClick={goToPageChatbots}>Go</button>
          <button onClick={nextPageChatbots} disabled={currentPage === Math.ceil(chatbots.length / chatbotsPerPage)} className="next-page">
            {t('nextPage')}
          </button>
          <button onClick={lastPageChatbots} disabled={currentPage === Math.ceil(chatbots.length / chatbotsPerPage)} className="last-page">
            {t('lastPage')}
          </button>
        </div>
  


      <Modal show={showChatbotModal} onHide={handleCloseChatbotModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('chatbot')}{t('details')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedChatbot && !isEditing ? (
            <div>
              <p><strong>{t('ID')}:</strong> {selectedChatbot.chatbot_id}</p>
              <p><strong>{t('name')}:</strong> {selectedChatbot.chatbot_name}</p>
              <p><strong>{t('alias')}:</strong> {selectedChatbot.chatbot_alias || selectedChatbot.chatbot_name}</p>
              <p><strong>{t('description')}:</strong> {selectedChatbot.chatbot_description}</p>
              <p><strong>{t('model')}:</strong> {selectedChatbot.chatbot_model}</p>
              <p><strong>{t('tokenUsage')}:</strong> {selectedChatbot.chatbot_token_used}</p>
              <p><strong>{t('APIKey')}:</strong> {maskApiKey(selectedChatbot.chatbot_api_key)}</p>
              <p><strong>{t('status')}:</strong> {selectedChatbot.chatbot_RAG_status ? t('customized') : t('general')}</p>
              <p><strong>{t('createdAt')}:</strong> {moment(selectedChatbot.chatbot_created_at).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</p>
              <p><strong>{t('updatedAt')}:</strong> {moment(selectedChatbot.chatbot_updated_at).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</p>
            </div>
          ) : (
            <Form>
              <Form.Group>
                <Form.Label>{t('alias')}</Form.Label>
                <Form.Control
                  type="text"
                  name="chatbot_alias"
                  value={editForm.chatbot_alias || editForm.chatbot_name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('description')}</Form.Label>
                <Form.Control
                  type="text"
                  name="chatbot_description"
                  value={editForm.chatbot_description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('model')}</Form.Label>
                <Form.Control
                  type="text"
                  name="chatbot_model"
                  value={editForm.chatbot_model}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('APIKey')}</Form.Label>
                <Form.Control
                  type="text"
                  name="chatbot_api_key"
                  value={maskApiKey(editForm.chatbot_api_key)} // Mask the API key in the form field
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('customizedOrGeneral')}</Form.Label>
                <ToggleSwitch
                  isChecked={editForm.chatbot_RAG_status}
                  onChange={handleStatusChange}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>

        <Modal.Footer>
          {isEditing ? (
            <Button variant="success" onClick={handleSaveClick}>
              Save
            </Button>
          ) : (
            <Button variant="primary" onClick={handleEditClick}>
              Edit
            </Button>
          )}
          {!isEditing && (
            <Button variant="danger" onClick={() => confirmDeleteChatbot(selectedChatbot.chatbot_id, selectedChatbot.chatbot_name)}>
              {t('delete')}
            </Button>
          )}
          <Button variant="secondary" onClick={handleCloseChatbotModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    </div>
  );
};

export default ChatbotsPage;
