import React, { useEffect, useState } from 'react';
import './IndexPage.css';
import { useTranslation } from 'react-i18next';

function Index() {
  const { t } = useTranslation();
  const [chatbots, setChatbots] = useState([]);
  const [imageTimestamp, setImageTimestamp] = useState(Date.now());

  // Fetch the list of public chatbots from the API
  useEffect(() => {
    fetch('https://dryangai.com/api/getPublicChatbots/', {
      method: 'POST',
    })
      .then(response => response.json())
      .then(data => {
        setChatbots(data.chatbots);
        setImageTimestamp(Date.now()); // Update timestamp to refresh images
      })
      .catch(error => console.error('Error fetching chatbots:', error));
  }, []);

  // Function to check if the user is logged in
  const isLoggedIn = () => {
    return localStorage.getItem('user') !== null;
  };

  // Handle Manage Your Chatbot button click
  const handleManageChatbot = () => {
    if (isLoggedIn()) {
      window.location.href = "https://dryangai.com/profile";
    } else {
      window.location.href = "https://dryangai.com/login";
    }
  };

  return (
    
    <div className="app">
      
      {/* Hero Section */}
      <section className="hero">
        {/* <h1 className="gradient-text">{t('Welcome to Dr. Yang AI')} ✨</h1> */}
        <h1 className="gradient-text">{t('Customize chatbots to be your personal assistants')}✨</h1>
        <div className="cta-buttons">
        <button 
            className="outline-btn" 
            onClick={() => window.location.href="https://dryangai.com/register"}>
            {t('Sign Up')}
        </button>
          <button 
            className="filled-btn" 
            onClick={() => window.open("https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=71", "_blank")}>

            {t('Try a Demo')}
          </button>
          <button 
            className="cta-button" 
            onClick={handleManageChatbot}>
            {t('Manage Your Chatbots')}
          </button>
        </div>

        <br />
        <br />
        <p className="cta-text">
        {t("Dr. Yang AI empowers you to design, build, and launch custom chatbots tailored to your needs, whether for personal use or your brand. It's perfect for managing interactions across all social platforms, making it ideal for individuals, startups, and large enterprises alike.")}</p>
      </section>
      
      {/* Public Chatbots Section */}
      <section className="public-chatbots">
        <h2>{t('Public Chatbots')}</h2>
        <div className="chatbot-cards">
          {chatbots.map(chatbot => (
            <a 
              key={chatbot.chatbot_id} 
              href={`https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=${chatbot.chatbot_id}`} 
              target="_blank" 
              rel="noopener noreferrer"
              className="chatbot-card-link"
            >
              <div className="chatbot-card">
                <img 
                  src={`https://dryangai.com/api/media/chatbots/${chatbot.chatbot_name}/Logo.png?t=${imageTimestamp}`} 
                  alt={chatbot.chatbot_name} 
                  className="chatbot-image" 
                />
                <div className="chatbot-tags">
                  <span className="tag">{chatbot.chatbot_language}</span>
                </div>
                <p className="chatbot-name">{chatbot.chatbot_alias}</p>
                <p className="chatbot-date">{new Date(chatbot.chatbot_created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                <div className="chatbot-rating">
                  <span className="star">★</span>
                  <span className="star">★</span>
                  <span className="star">★</span>
                  <span className="star">★</span>
                  <span className="star">★</span>
                </div>
              </div>
            </a>
          ))}
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <h2>{t('Features')}</h2>
        <div className="feature-list">
          <div className="feature-item">
            <h3>{t('Customized Chatbots')}</h3>
            <p>{t('Create chatbots tailored to your unique knowledge by uploading documents and resources.')}</p><p><a href="https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=98" >{t('Company Chatbot Demo')}</a></p>
          </div>
          <div className="feature-item">
            <h3>{t('AI Companions')}</h3>
            <p>{t('Design AI companions with distinct personalities. They can remember your conversation history, making them trustworthy partners with whom you can share your thoughts and memories.')}</p><p><a href="https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=101" >{t('AI Girlfriend Demo')}</a></p><p><a href="https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=134" >{t('AI Girlfriend Demo (Chinese)')}</a></p>
          </div>
          <div className="feature-item">
            <h3>{t('AI Assistant')}</h3>
            <p>{t('Your AI assistant can efficiently manage and retrieve information from your documents, significantly improving your productivity.')}</p><p><a href="https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=71" >{t('Coding Assistant Demo')}</a></p>
          </div>
          <div className="feature-item">
            <h3>{t('AI-Powered Conversations')}</h3>
            <p>{t('Utilize advanced AI models to enable intelligent responses and smooth interactions with users.')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('Effortless Usage')}</h3>
            <p>{t('Each chatbot is assigned a unique link, allowing you to chat with it from anywhere. Easily integrate your chatbot into websites, apps, or other platforms using our API.')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('Seamless Integration')}</h3>
            <p>{t('Effortlessly embed your chatbot into websites, apps, or platforms with a single line of code or API. For added security, you can control where your chatbot is accessible.')}</p>
          </div>
          <div className="feature-item">
            <h3>{t('Shareable')}</h3>
            <p>{t('Share your chatbot with friends, family, or colleagues by providing them with its unique link. You can also set a password to protect access for privacy control.')}</p><p><a href="https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=98" >{t('Chat Box')}</a></p><p><a href="https://dryangai.com/api/media/chatbots/examples/chatbot_Bubble.html?chatbotID=98" >{t('Chat Bubble')}</a></p>
          </div>
          <div className="feature-item">
            <h3>{t('Multiple AI Models')}</h3>
            <p>{t('Select from a diverse range of cutting-edge large language models (LLMs) for your chatbot, including GPT, Llama, Mistral, Gemma, Phi, and more. Additionally, uncensored models are available to unlock new possibilities.')}</p>
            </div>
          <div className="feature-item">
            <h3>{t('Multimodal Capabilities')}</h3>
            <p>{t('Our chatbot can process various types of inputs, including text, tables, plots, audio, images, and video.')}</p><p><a href="https://dryangai.com/api/media/chatbots/examples/chatbot_Box.html?chatbotID=87" >Visual AI</a></p>
          </div>

        </div>
      </section>


      {/* Video Section */}
      <section  className="video-section">
        <h2>{t('Video Demo')}</h2>             
        <div className='video-list' id="video">
        <div className='video-item' >
            <video 
                src="https://dryangai.com/api/media/videos/chatbotDemo.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline
                width="100%" // Adjust the width as needed
                style={{ borderRadius: '0px' }} // Fixed value for uniform round corners
            />
        </div>
      </div>
     </section>

    

      {/* Pricing Section */}
      <section id="pricing" className="pricing">
        <h2>{t('Pricing')}</h2>
        <div className="pricing-table">

    {/* Free Plan */}
    <div className="pricing-option">
      <h3>{t('Free')}</h3>
      <p>{t('Free Trial')}</p>
      <p><span className='priceValue'>$0</span> </p>
      <p>{t('Unleash your creativity.')}</p>
      <button onClick={() => window.location.href="https://dryangai.com/register"}>{t('Get Started')}</button>
      <ul>
        <li>{t('1 active Chatbot')}</li>
        <li>{t('Create 2 Chatbots')}</li>
        <li>{t('Custom Chatbot Name, Photo')}</li>
        <li>{t('Custom Chatbot Knowledge')}</li>
        <li>{t('Custom Chatbot Personality')}</li>
        <li>{t('Custom Example Questions')}</li>
        <li>{t('Deploy to')} 1 {t('Websites')}</li>
      </ul>
    </div>

    {/* Simplified One Plan */}
    <div className="pricing-option">
      <h3>{t('Pro')}</h3>
      <p>{t('Best for personal use.')}</p>
      <p><span className='priceValue'>$9.99/{t('month')}</span></p>
      <p>{t('More chatbots & customization.')}</p>
      <button onClick={() => window.location.href="https://dryangai.com/register"}>{t('Get Started')}</button>
      <ul>
        <li>{t('10 active Chatbots')}</li>
        <li>{t('Create 20 Chatbots')}</li>
        <li>{t('Custom Chatbot Name, Photo')}</li>
        <li>{t('Custom Chatbot Knowledge')}</li>
        <li>{t('Custom Chatbot Personality')}</li>
        <li>{t('Custom Name, Logo, Greeting')} </li>
        <li>{t('Custom Example Questions')} </li>
        <li>{t('Deploy to')} 10 {t('Websites')}</li>
      </ul>
    </div>

    {/* Simplified Enterprise Plan */}
    <div className="pricing-option">
      <h3>{t('Enterprise')}</h3>
      <p>{t('Best for teams and agencies.')}</p>
      <p><span className='priceValue'>{t("Let's talk")}</span></p>
      <p> {t('Tailored plan to meet your needs.')}</p>
      <button onClick={() => window.location.href = 'mailto:samtariyang@gmail.com'}>{t('Get Started')}</button>
      <ul>
        
        <li>{t('Unlimited Chatbots')}</li>
        <li>{t('Unlimited AI Tokens')}</li>
        <li>{t('Deploy to Unlimited Websites')}</li>
        <li>API {t('Access')}</li>
        <li>{t('Full Customization')}</li>
        <li>{t('Workflow Automations')}</li>
        <li>{t('Priority Support')}</li>
      </ul>
    </div>

  </div>
</section>



      {/* About Section */}
      <section id="about" className="about">
        <h2>{t('About Dr. Yang AI')}</h2>
        <p>
          {t('Dr. Yang AI is a cutting-edge platform that enables users to create highly customizable chatbots. By simply providing your own documents or resources, our AI can generate chatbots with knowledge tailored to your specific needs, making customer interactions seamless and personalized.')}
        </p>
      </section>

      {/* Footer */}
      <footer id="contact" className="footer">
        <div className="footer-info">
          <p>{t('Contact us')}: samtariyang@gmail.com</p>
          <p>&copy; 2024 {t('Dr. Yang AI. All rights reserved.')}</p>
        </div>
      </footer>
    </div>
  );
}

export default Index;
